.navbar-topic:hover {
    background: #2D4A9D;
    color: #fff;
    font-size: 1.3rem;
    font-weight: 400;
    display: inline-block;
    line-height: 22px;
    padding: 0.6rem 1rem;
    border-radius: 3px;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    transition-property: color;
    transition-duration: .3s;
    border: 0;
    cursor: pointer;
}

.for-background {
    /* font-family: 'Poppins';
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    background: #2D4A9D;
    border-radius: 10px; */
    font-family: 'Poppins';
    background: #2D4A9D;
    color: #fff;
    font-size: 1.3rem;
    font-weight: 400;
    display: inline-block;
    line-height: 22px;
    padding: 0.6rem 1rem;
    border-radius: 3px;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    transition-property: color;
    transition-duration: .3s;
    border: 0;
    text-transform: capitalize;
}


.for-background:hover {
    /* font-family: 'Poppins';
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    background: #2D4A9D;
    border-radius: 10px; */
    font-family: 'Poppins';
    background: #2D4A9D;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 400;
    display: inline-block;
    line-height: 22px;
    padding: 0.6rem 1rem;
    border-radius: 3px;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    transition-property: color;
    transition-duration: .3s;
    border: 0;
}

.love-to-hear {
    color: #2D4A9D;
}

.for-making-sticky-navbar {
    position: sticky;
    top: 0;
    z-index: 999;

}

.navbar-button {
    width: 126px;
    height: 60px;
    background: #2D4A9D;
    border-radius: 10px;
}

.not-selected {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #2D4A9D;
}

.curve-navbar {
    background-color: aliceblue;
    border-radius: 10px;
    color: #0076BD;
}

@media (max-width: 768px) {
    .navbarContainer {
        display: none;
    }

    .love-to-hear {
        text-align: center;
    }
}