.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.f-1 {
  font-size: 1rem;
}

.f-1-1 {
  font-size: 1.1rem;
}

.f-1-2 {
  font-size: 1.2rem;
}

.f-1-5 {
  font-size: 1.5rem;
}

.f-2 {
  font-size: 2rem;
}

.f-3 {
  font-size: 3rem;
}
.f-400 {
  font-weight: 400;
}
.f-500 {
  font-weight: 500;
}

.text-center {
  text-align: center;
}

.pagination{
  background-color: #2D4A9D;
  border-radius: 5px;
  height: 40px;
}
.pagination button{
  background-color: #2D4A9D;
  border: none;
  border-radius: 0;
}
.pagination button:hover{
  background-color: #2D4A9D;
}
.paginationContainer svg{
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0px;
}
.paginationContainer p{
    border-right: 1px solid rgb(229, 231, 235);
    width: 50px;
    text-align: center;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
}


.searchBox{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border: 1px solid rgb(229, 231, 235);
  border-radius: 5px;
  height: 40px;
}
.searchBox input{
  background-color: transparent;
  border: none;
  outline: none;
}