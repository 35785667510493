.leadership {
    width: 300px;
    padding-left: 21.5px;
    padding-right: 21.5px;
    padding-top: 6px;
    padding-bottom: 5px;
    background-color: #081b24;
    color: white;
}

.forTextHover {
    cursor: pointer;
    padding-left: 5px;
    transition-duration: 500ms;
}

.forTextHover:hover {
    background-color: #2D4A9D;
}

.picture-shadow {
    border: 3px solid #2D4A9D;
    box-shadow: rgb(0 0 0 / 25%) 10px 8px 4px;
}

.leadership-info {
    width: 300px;
    background-color: #2D4A9D;
    padding: 2rem;
    margin-top: 2rem;
    color: white;
}

.leadership-heading {
    color: #2D4A9D;
    font-family: 'Poppins';
    font-weight: 500;
}

.font-bold-heading {
    font-size: 25px;
    font-weight: bold;
}

.hover-table {
    height: 60px;
}

.hover-table-top-row {
    background-color: aliceblue;
}

.hover-table:hover {
    background-color: aliceblue;
}

.teacher-paragraph {
    font-size: 18px;
}

@media screen and (max-width: 768px) {
    .leadership {
        display: none;
    }

    .leadership-info {
        display: none;
    }

}