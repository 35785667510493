.sidebar_main {
    display: none;
}

.sidebar_item {
    transition: all 0.5s ease;
    padding: 0 0.5rem;
}

.sidebar_item:hover {
    background-color: #0076BD;
}

.selected {
    background-color: #0076BD;

}

.sidenav_mob {
    display: none;
}

.color-white {
    color: white;
}

@media(max-width:640px) {
    .sidebar_item {
        padding: 10px;
    }

    .sidenav_mob {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .sidenav_list {
        margin-top: 1rem;
        opacity: 0;
        height: 0;
        overflow: hidden;
        transition: all 0.2s linear;
    }

    .sidenav_list_visible {
        opacity: 1;
        height: 100%;
        transition: all 0.2s linear;
    }

    .arrow-down {
        transform: rotate(90deg);
    }

    .arrow-up {
        transform: rotate(-90deg);
    }

    .sidebar_main {
        min-width: 88vw;
        padding-left: 21.5px;
        padding-right: 21.5px;
        padding-top: 6px;
        padding-bottom: 0px;
        background-color: #081b24;
        display: block
    }
}