.instute {
    font-family: 'Poppins';
    font-size: 36px;
    color: #121212;
    text-shadow: 1px 1px 1px aliceblue,
        1px 1.5px 1px aliceblue,
        1px 2px 1px aliceblue,
        1px 2.5px 1px aliceblue,
        1px 3px 1px aliceblue,
        1px 3.5px 1px aliceblue,
        1px 4px 1px aliceblue,
        1px 4.5px 1px aliceblue,
        1px 5px 1px aliceblue,
        1px 6px 1px aliceblue,
        1px 6px 6px rgba(16, 16, 16, 0.4),
        1px 12px 10px rgba(16, 16, 16, 0.2),
        1px 18px 35px rgba(16, 16, 16, 0.2),
        1px 24px 60px rgba(16, 16, 16, 0.4);


}

.bed-college {
    font-family: 'Poppins';
    font-size: 27px;
    color: #121212;
}



.children-village {
    width: 150px;
    height: 60px;
}

.kaul-institute {
    font-family: 'Poppins';
    font-size: 24px;
    line-height: 29px;
    color: #2D4A9D;
}

.hover-picture:hover {
    /* width: 870px;
    height: 520px; */
}

.hover-picture {
    width: 850px;
    height: 500px;
}

.education-is-power {
    font-size: 36px;
    line-height: 51px;
    color: #121212;
}

.welcome {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 30.5px;
    line-height: 51px;
    color: #000000;
}

.welcome-line {
    border-bottom: 4px solid #2D4A9D;
}

.institute-picture {
    width: 545.03px;
    height: 310px;
    border: 1px solid #187498;
    border-radius: 10px;
}

.about-sos {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 28px;
    display: flex;
    align-items: center;
    color: #2D4A9D;
}

.sos-para {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
}

.card-info {
    padding: 0;
    margin-top: 25px;
}

.infructructure {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    color: #2D4A9D;
}

.see-more {
    color: #2D4A9D;
    cursor: pointer;
}

.infructure-card {
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 15px;
}

/* .gaping {
    margin-right: 20px;
} */

.middle-background {
    background: #F9F8F8;
}

.non-infructure-card {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 15px;
}

.photoGallery-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 30.5px;
    line-height: 51px;
    color: #000000;

}

.gallery-photo {
    height: 350px;
    border-radius: 10px;
    width: 92%;
}

.slider-image {
    border: 0;
}


.contact-input {
    height: 60px;
    background: #F5F5F5;
    border-radius: 10px;
}

.font {
    font-weight: 550;
}

.input-field {
    width: 100% !important;
    height: 60px;
    background: #F5F5F5;
    border-radius: 10px;
    padding-right: 9px;
}

.input-field:focus {
    outline: none;
}


.drop-details {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: #121212;
}

.submit-button {
    width: 260px;
    height: 60px;
    background: #2D4A9D;
    border-radius: 10px;
}

.submit {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;

}

.Footer {
    width: 100%;
    background: #2D4A9D;
    color: #FFFF;
}

.footer-heading {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 28px;
    line-height: 41px;
    color: #FFFFFF;
}

.flexProp {
    display: flex;
    justify-content: flex-start;
}

.contact-info {
    font-family: 'Poppins';
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}

.margin {
    margin-left: 80px;
}

.margin-right {
    margin-left: 80px;
}

.margin-for-institute {
    margin-top: 85px;
}

.facility-picture {
    height: 280px;
    margin-left: 15px;
    margin-right: 15px;
}

.infrustructure-card-para {
    font-size: 19px;
}

.card-size {
    width: 350px;
    height: 500px;
}

.large-card {
    width: 350px;
    height: 570px;
}

.large-card-psychology {
    width: 350px;
    height: 565px;
}

.larger-card-science {
    width: 350px;
    height: 770px;
}

.large-card-ict {
    width: 350px;
    height: 600px;
}

.larger-card-seminer {
    width: 350px;
    height: 837px;
}

.larger-card-health {
    width: 350px;
    height: 865px;
}

.larger-card-lab {
    width: 350px;
    height: 980px;
}

.larger-card-art {
    width: 350px;
    height: 750px;
}

.larger-card {
    width: 350px;
    height: 750px;
}

.infrastructure-paragraph {
    border: 2px solid blueviolet;
    border-radius: 10px;
    width: 1000px;
    padding: 10px;
    transition-duration: 1000ms;
}

.infrastructure-paragraph:hover {
    border: 2px solid brown;
    border-radius: 10px;
    width: 1000px;
    padding: 10px;
    display: flex;
    justify-content: center;
    background-color: #18749841;
}

.Carousel-image {
    width: 100%;
}

.contuct-information {
    height: 120vh;
}

.table-picture {
    width: 20vh;
}

.for-opacity {
    opacity: 0.4;
}

.for-background-home {
    background: #2D4A9D;
    color: #fff;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 22px;
    padding: 0.6rem 1rem;
    border-radius: 3px;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    transition-property: color;
    border: 0;
    width: 30%;
    text-transform: capitalize;
}

.for-background-home:hover {
    transition-duration: 1000ms;
    font-size: 1.5rem;
    background: #2D4A9D;
}

.for-background-home-mobile {
    background: #2D4A9D;
    color: #fff;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 22px;
    padding: 0.6rem 1rem;
    border-radius: 3px;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    transition-property: color;
    transition-duration: .3s;
    border: 0;
    width: 100%;
}

.divide {
    position: absolute;
    top: 0;
    border-left: 2px solid gray;
    height: 4000px;
    margin: 0;
    z-index: 999;
}

.home-button {
    position: absolute;
    top: 30px;
    left: 50px;
    cursor: pointer;
}

.slick-arrow {
    width: 20px;
    background-color: coral;
}

.changed-picture {
    background-color: coral;
}

.left-arrow-button {
    z-index: 1;
    position: absolute;
    left: 55px;
    margin-top: 160px;
}

.right-arrow-button {
    z-index: 1;
    position: absolute;
    right: 55px;
    margin-top: 160px;
}

.teachers-picture {
    width: 200px;
    height: 200px;
}

.about-institution-picture {
    width: 350px;
    height: 280px;
}

.updated-font-family {
    font-family: 'Poppins';
}

@media only screen and (min-width: 1550px) {
    .hover-picture {
        width: 1100px;
        height: 650px;
        margin-left: 100px;
    }

    .instute {
        font-size: 46px;
    }

    .bed-college {
        font-family: 'Poppins';
        font-size: 37px;
        color: #121212;
    }

    .education-is-power {
        font-size: 46px;
        line-height: 51px;
        color: #121212;
    }
}

.kept-top {
    position: absolute;
    top: 90px;

}

.study-image-picture {
    /* width: 800px; */
    height: 320px;
    width: 650px;
    border: 2px solid rgb(45, 74, 157);
    border-radius: 5px;
}

.moving-para {
    transform: translateX(100%);
    animation: moving 15s linear infinite;
}

@keyframes moving {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-50%);
    }
}

.menuIconContainer {
    display: none;
}

.applyBtn {
    font-size: 1.2rem;
    padding: 1rem 2.5rem;
    font-family: 'Poppins';
    border: none;
    outline: none;
    border-radius: 0.4rem;
    cursor: pointer;
    text-transform: uppercase;
    background-color: #2D4A9D;
    color: rgb(234, 234, 234);
    font-weight: 700;
    transition: 0.6s;
    box-shadow: 0px 0px 60px #1f4c65;
    animation: moving2 3s ease-in-out infinite;
}

@keyframes moving2 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.1);
    }
}

.applyBtn:hover {
    background: #00abec;
    color: white
}


@media (max-width: 768px) {
    .kept-top {
        position: relative;
    }

    .hero-content {
        padding: 0 1rem 1rem 1rem !important;
    }

    .instute {
        font-size: 26px;
        text-align: center;
    }


    .bed-college {
        font-size: 20px;
        text-align: center;
    }

    .education-is-power {
        text-align: center;
        font-size: 26px;
    }

    .home-button {
        top: 30px;
        left: 17px !important;
    }

    .abs-class-large {
        margin-bottom: 25px !important;
        margin-top: -158px !important;
    }

    .moving-para {
        font-size: 19px;
    }


    /* about */
    .margin-for-institute {
        padding: 1rem 1rem 1rem 1rem !important;
        margin-top: 0px;
    }

    .aboutWelcome {
        font-size: 26px !important;
        line-height: 40px !important;
        margin-bottom: 5px;
        text-align: center;
    }

    .about-institution-picture {
        margin-bottom: 15px;
        width: 100% !important;
    }

    .about-sos {
        font-size: 24px;
        margin-bottom: 5px;
    }

    /* infrastructure */
    .middle-background {
        padding: 1rem 1rem 1rem 1rem !important;
    }

    .infrustructure {
        font-size: 26px !important;
        /* line-height: 40px !important; */
        margin-bottom: 5px;
        text-align: center;
    }

    /* photo Gallery */
    .photoGalleryContainer {
        padding: 1rem 1rem 1rem 1rem !important;
    }

    .gallery-photo {
        width: 100% !important;
    }

    .left-arrow-button {
        left: 18px !important;
    }

    .right-arrow-button {
        right: 18px !important;
    }


    /* Menu icon */

    .menuIconContainer {
        display: block;
    }

    .menuIcon {
        background-color: #2D4A9D;
        height: 30px;
        width: 30px;
        position: absolute !important;
        top: 28px !important;
        right: 17px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
    }

    .navbarMobile {
        display: flex;
        padding: 20px;
        justify-content: space-between;
        margin-bottom: 0px;
        background-color: white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        position: fixed;
        top: 0;
        z-index: 999;
        width: 100%;
    }

    .navbarMobile .home-button {
        position: relative;
        top: 0;
        left: 0 !important;
    }

    .navbarMobile .menuIcon {
        position: relative !important;
        top: 0 !important;
        right: 0 !important;
    }

    .footer {
        row-gap: 0 !important;
    }

    .backgroundImgae {
        display: none !important;
    }
}