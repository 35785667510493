.NaacWrapper {
    padding: 20px;
}

.tableFontStyle {
    font-size: 16px !important;
    font-weight: 500 !important;
}

.text-custom-blue {
    color: #2D4A9D !important;
}